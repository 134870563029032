export class CheckAuth {
  static readonly type = '[Auth] Check auth';
  constructor() {}
}

export class FetchCurrentUser {
  static readonly type = '[Auth] Fetch current user';
  constructor() {}
}

export class SignOut {
  static readonly type = '[Auth] Sign out';
  constructor() {}
}
