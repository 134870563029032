import { Component, Input, OnInit } from '@angular/core';
import { IAction } from '@shared/classes/view/ButtonAction';
import { AppBreadcrumbService } from 'app/app.breadcrumb.service';
import { BreadCrumb } from 'app/shared/classes';

@Component({
  selector: 'app-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss'],
})
export class BasePageComponent implements OnInit {
  private _breadCrumb: BreadCrumb = { items: [] };

  get breadCrumb() {
    return this._breadCrumb;
  }

  @Input() set breadCrumb(breadCrumb: BreadCrumb) {
    this._breadCrumb = breadCrumb;
    this.breadcrumbService.setItems(breadCrumb.items);
    // this.breadcrumbService.setShowViewModeSwitch(false);
  }
  private _showTreeSwitch: boolean = false;

  get showTreeSwitch() {
    return this._showTreeSwitch;
  }

  @Input() set showTreeSwitch(showTreeSwitch: boolean) {
    this._showTreeSwitch = showTreeSwitch;
    this.breadcrumbService.setShowTreeSwitch(showTreeSwitch);
  }
  private _treeIcon: 'pi pi-sitemap' | 'fa-folder-tree fas' | 'fas fa-table-columns' | 'pi pi-th-large' = 'fa-folder-tree fas';

  get treeIcon() {
    return this._treeIcon;
  }

  @Input() set treeIcon(treeIcon: 'pi pi-sitemap' | 'fa-folder-tree fas' | 'fas fa-table-columns' | 'pi pi-th-large') {
    this._treeIcon = treeIcon;
    this.breadcrumbService.setTreeIcon(treeIcon);
  }
  private _showViewSwitch: boolean = false;
  get showViewSwitch() {
    return this._showViewSwitch;
  }
  @Input()
  set showViewSwitch(showViewSwitch: boolean) {
    this._showViewSwitch = showViewSwitch;
    this.breadcrumbService.setShowViewModeSwitch(showViewSwitch);
  }
  @Input() cardContainer: boolean = true;

  @Input() pageActions: IAction[] = [];
  @Input() pageActionsCommandData: any = null;
  @Input() listType: 'split' | 'list' | 'set' | 'speed' = 'split';

  constructor(private breadcrumbService: AppBreadcrumbService) {
    this.breadcrumbService.setShowViewModeSwitch(false);
    this.breadcrumbService.setShowTreeSwitch(false);
  }

  ngOnInit(): void {}
}
