<ng-container>
  <div class="w-full">
    <ng-content select="[page-notice]"></ng-content>
    <div [class]="cardContainer ? 'card' : ''">
      <div
        class="flex justify-content-between"
        [class.flex-row-reverse]="pageActions.length > 0"
        [class.mb-2]="pageActions.length > 0"
      >
        @if (pageActions.length > 0) {
          <app-button-list
            class="align-self-center"
            [listType]="listType"
            [actionList]="pageActions"
            [commandData]="pageActionsCommandData"
          ></app-button-list>
        }
        <ng-content select="[page-header]"></ng-content>
      </div>
      <ng-content></ng-content>
      <ng-content select="[page-footer]"></ng-content>
    </div>
  </div>
</ng-container>
