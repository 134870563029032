import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseToFixedNumber',
})
export class ParseToFixedNumberPipe implements PipeTransform {
  transform(value: any, parseToFloat: 'float' | 'int' = 'float', args?: any): number {
    if (typeof value === 'number') return parseToFloat == 'float' ? value : parseInt(value + '');
    if (typeof value === 'string') return parseToFloat == 'float' ? parseFloat(value) : parseInt(value);
    else return 0;
  }
}
