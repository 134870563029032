import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LocaleService } from '@core/services/locale/locale.service';
import { ActionRole, IAction, isNullObj } from '@shared/classes';
import { groupBy } from 'lodash-es';
import { NgxPermissionsService } from 'ngx-permissions';

@Component({
  selector: 'app-button-list',
  templateUrl: './button-list.component.html',
  styleUrls: ['./button-list.component.scss'],
})
export class ButtonListComponent implements OnInit {
  private _actionList: IAction[] = [];
  private _displayData: any;
  @Input() set actionList(actionList: IAction[]) {
    this.getValidActions(actionList).then((actions) => {
      this._actionList = [...actions].map((action) => {
        return {
          ...action,
          label: isNullObj(action?.label)
            ? action?.label
            : this.localeService?.translate('general.actions.' + action?.label?.toLowerCase(), action.label),
        };
      });
      this.getSplitList();
      this.splitListByGroup();
    });
  }
  @Output() onSpeedDial: EventEmitter<any> = new EventEmitter();
  @Input() appendTo: any = null;
  @Input() listType: 'split' | 'list' | 'set' | 'speed' | 'dropdown' = 'split';
  @Input() commandData: any;
  @Input() itemClass: any = '';
  @Input() useStaticLeftRightForSplit: any = '';
  @Input() listTypeItemClass: any = null;
  @Input() set displayData(displayData: any) {
    this._displayData = displayData;
    this.getSplitList();
    this.splitListByGroup();
  }
  get actionList() {
    return this._actionList;
  }
  get displayData() {
    return this._displayData;
  }
  mainActions: IAction[] = []; //@TODO: make it accept list of splitted action list
  secondaryActions: IAction[] = [];
  groupedActionsList: { id: any; type: 'split' | 'list' | 'set' | 'speed' | 'dropdown'; list: IAction[] }[];
  dropdownAction: IAction = {
    id: 1,
    label: 'Actions',
    buttonType: 'button',
    icon: 'pi pi-angle-down',
  };
  @Input() set dropdownActionIcon(icon: string) {
    this.dropdownAction = {
      ...this.dropdownAction,
      icon: icon === '' ? '' : icon || 'pi pi-angle-down',
    };
  }
  @Input() set dropdownActionLabel(label: string) {
    this.dropdownAction = {
      ...this.dropdownAction,
      label: label === '' ? '' : label || 'Actions',
    };
  }
  @Input() set dropdownActionColor(
    label: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'help' | 'danger'
  ) {
    this.dropdownAction = {
      ...this.dropdownAction,
      color: label,
    };
  }
  @Input() set dropdownActionStyleClass(styleClass: string) {
    this.dropdownAction = {
      ...this.dropdownAction,
      buttonClass: styleClass,
    };
  }
  @Input() set dropdownActionStyle(
    style: 'raised' | 'rounded' | 'text' | 'raised-text' | 'outlined' | 'outlined-rounded' | 'rounded-text'
  ) {
    this.dropdownAction = {
      ...this.dropdownAction,
      buttonStyle: style,
    };
  }
  constructor(
    private permissionsService: NgxPermissionsService,
    public localeService: LocaleService
  ) {}

  ngOnInit(): void {}
  splitListByGroup() {
    let groupedActions = groupBy(this.actionList, 'group.id');

    let actions: { id: any; type: 'split' | 'list' | 'set' | 'speed' | 'dropdown'; list: IAction[] }[] = [];
    Object.keys(groupedActions).forEach((key) => {
      actions.push({
        id: key,
        type: groupedActions[key][0]?.group?.type ?? 'list',
        list: groupedActions[key],
      });
    });
    this.groupedActionsList = actions;
  }
  async getSplitList() {
    const displayedActions = this.actionList.filter((x) =>
      x?.displayCommand ? x?.displayCommand(this.displayData) : true
    );

    this.mainActions = displayedActions.filter((x) => x.role == ActionRole.Primary);
    if (this.mainActions.length <= 0 && displayedActions.length > 0) {
      this.mainActions.push(displayedActions[0]);
      this.secondaryActions = displayedActions.slice(1).map((x) => {
        return {
          ...x,
          command: this.splitMenuClick.bind(this),
          visible: x?.displayCommand ? x?.displayCommand(this.displayData) : true,
        };
      });
    } else {
      this.secondaryActions = displayedActions
        .filter((x) => x.role != ActionRole.Primary)
        .map((x) => {
          return {
            ...x,
            command: this.splitMenuClick.bind(this),
            visible: x?.displayCommand ? x?.displayCommand(this.displayData) : true,
          };
        });
    }
  }

  actionClick(action: IAction) {
    if (this.commandData) {
      action.command(this.commandData);
    } else {
      action.command();
    }
  }
  splitMenuClick(event: { originalEvent: any; item: IAction }) {
    let action = this.actionList.find((x) => x.id == event.item.id); //safe for null checks since id is required in model
    if (this.commandData) {
      action?.command(this.commandData);
    } else {
      action?.command();
    }
  }
  speedDialClick(data) {
    data.stopPropagation();
    data.preventDefault();
    this.onSpeedDial.emit(this.commandData);
  }
  async getValidActionsAsync(actions: IAction[]): Promise<IAction[]> {
    const validActions: IAction[] = [];

    for (const action of actions) {
      if (action.permission) {
        // Check permission using the permissions service
        const hasPermission = await this.permissionsService.hasPermission(action.permission);

        if (hasPermission) {
          validActions.push(action);
        }
      } else {
        // If permission is not defined, consider it valid
        validActions.push(action);
      }
    }

    return validActions;
  }
  async getValidActions(actions: IAction[]): Promise<IAction[]> {
    const permissionPromises: Promise<{ hasPermission: boolean; action: IAction }>[] = [];

    actions.forEach((action) => {
      if (action.permission) {
        const permissionPromise = this.permissionsService.hasPermission(action.permission).then((hasPermission) => ({
          hasPermission,
          action,
        }));
        permissionPromises.push(permissionPromise);
      } else {
        permissionPromises.push(Promise.resolve({ hasPermission: true, action }));
      }
    });

    const permissionResults = await Promise.all(permissionPromises);

    const validActions = permissionResults.filter((result) => result.hasPermission).map((result) => result.action);

    return validActions;
  }
}
