<p-toast class="toast-z-index"></p-toast>
<p-toast class="toast-z-index" position="top-left" key="tl"></p-toast>
<p-toast class="toast-z-index" position="top-right" key="tr"></p-toast>
<p-toast class="toast-z-index" position="bottom-left" key="bl"></p-toast>
<p-toast class="toast-z-index" position="bottom-right" key="br"></p-toast>
<p-toast class="toast-z-index" position="top-center" key="tc"></p-toast>
<p-toast class="toast-z-index" position="bottom-center" key="bc"></p-toast>
<p-toast class="toast-z-index" position="center" key="c"></p-toast>

<p-confirmDialog
  [style]="{ width: '50vw' }"
  key="positionDialog"
  [position]="dialogService.position"
  [baseZIndex]="9999999"
  [rejectButtonStyleClass]="dialogService.rejectButtonStyleClass"
></p-confirmDialog>
<p-confirmDialog
  [style]="{ width: '50vw' }"
  [baseZIndex]="9999999"
  [rejectButtonStyleClass]="dialogService.rejectButtonStyleClass"
></p-confirmDialog>
<p-confirmPopup key="popup"></p-confirmPopup>

<router-outlet></router-outlet>
<!-- <p-blockUI [blocked]="(loaderService.loadingState | async)"></p-blockUI> -->
