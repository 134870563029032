@switch (listType) {
  @case ('split') {
    @if (secondaryActions.length > 0) {
      <ng-container *ngTemplateOutlet="splitTemplate"></ng-container>
    } @else {
      <div class="gap-1 flex flex-row justify-content-start w-full">
        @for (action of mainActions; track action) {
          @if (displayData | hideOrDisplayAction: action) {
            <app-button
              [displayData]="displayData"
              [commandData]="commandData"
              [action]="action"
              [class]="itemClass"
            ></app-button>
          }
        }
      </div>
    }
  }
  @case ('list') {
    <ng-container *ngTemplateOutlet="listTemplate"></ng-container>
  }
  @case ('set') {}
  @case ('speed') {
    <p-speedDial
      [model]="actionList"
      [radius]="0"
      type="circle"
      showIcon="pi pi-ellipsis-v"
      hideIcon="pi pi-times"
      buttonClassName="p-button-icon-only p-button-plain p-button-text"
      class="w-full h-full"
      className="speeddial-left"
      (onClick)="speedDialClick($event)"
    >
      <!-- <ng-template pTemplate="button">
      <button pbutton="" pripple="" icon="pi pi-ellipsis-v"
        class="p-element p-ripple p-button-text p-button-plain p-button-rounded p-button p-component p-button-icon-only">
        <span class="p-button-icon pi pi-ellipsis-v" aria-hidden="true"></span>
        <span class="p-ink"></span>
      </button>
    </ng-template> -->
    </p-speedDial>
  }
  @case ('dropdown') {
    <app-button
      [displayData]="displayData"
      [commandData]="commandData"
      [action]="dropdownAction"
      (onClickAction)="actionListDropdownMenu.toggle($event); $event.stopPropagation()"
      [class]="itemClass"
    ></app-button>
    <p-menu #actionListDropdownMenu [popup]="true" [model]="actionList" appendTo="body"></p-menu>
  }
  @default {}
}

<ng-template #listTemplate>
  <div class="gap-1 flex flex-row justify-content-start w-full">
    @for (item of groupedActionsList; track item) {
      @if (item.type == 'list') {
        @for (action of item.list; track action) {
          <ng-container [class]="itemClass">
            @if (displayData | hideOrDisplayAction: action) {
              <app-button
                [displayData]="displayData"
                [commandData]="commandData"
                [action]="action"
                [class]="listTypeItemClass || itemClass"
              ></app-button>
            }
          </ng-container>
        }
      }
      @if (item.type == 'split') {
        <app-button-list
          [appendTo]="appendTo"
          [class]="itemClass"
          [itemClass]="itemClass"
          [actionList]="item.list"
          [listType]="'split'"
          [commandData]="commandData"
          [displayData]="displayData"
        ></app-button-list>
      }
    }
  </div>
</ng-template>

<ng-template #splitTemplate>
  @for (mAction of mainActions; track mAction) {
    <p-splitButton
      [label]="mAction.label"
      [icon]="mAction.icon"
      (onClick)="actionClick(mAction)"
      [model]="secondaryActions"
      [styleClass]="(mAction | getButtonClass) + ' ' + itemClass"
      [iconPos]="$any(mAction.iconPos) || 'left'"
      [appendTo]="appendTo"
      [menuStyleClass]="useStaticLeftRightForSplit ? 'left-auto right-0' : ''"
    >
    </p-splitButton>
  }
</ng-template>
